<div class="about-container">
    <h1>
        About Me
    </h1>
    <div class="about-body">
        <div>
            <p>
                My name is Zack Turman, and I'm a Unity game developer. Over the last three years I have built a portfolio across several genres of games including 
                2D roguelite adventure game, 3D top-down sci-fi shooter, and first-person horror. I'm drawn to horror and fantasy games, but love programming the rules that 
                serve as a foundation of practically any game. 
            </p>
            <p>
                During my full-time work, I work as a software developer for desktop applications. I'm drawn to creating more efficient processes for people and have built skills
                in Agile and improved onboarding processes for employees. I've also created company-wide version control strategy and developed custom tools to compile and improve
                management of code bases. 
            </p>
            <p>
                I also run a game development club which focuses on co-working and accomplishing development goals. Through the club, I've contributed to game projects in various 
                leadership roles through our Popped Corn projects. I've acted as the lead designer for an Unreal game jam, and the lead developer and project manager for Under Rail, developed in Unity. 
                For longer-term project, I try to create studio-like collaborative projects which focuses on open-source-like development. Brass Necessity, for example was developed as part of this group.
            </p>
        </div>
        <img src="../../assets/profile-picture.png" alt="profile-picture" class="profile-picture">
    </div>

    <h1>
        Links
    </h1>
    <div class="my-links">
        <p>
            <a href="https://www.meetup.com/bristol-game-creators-club/">Check out the club</a>
        </p>
        <p>
            <a href="https://www.poppedcornbytes.io/home">Check out Popped Corn</a>
        </p>
        <p>
            <a href="https://www500.itch.io/">Check out my Itch profile</a>
        </p>
    </div>
</div>
