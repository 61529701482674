import { SafeHtml } from "@angular/platform-browser";

export class ProjectInformation{
  public id: number;
  public title: string;
  public rawVideoLink: string;
  public sanitizedVideoLink: SafeHtml;
  public backgroundColour: string;
  public projectSummary: string;
  public codeLink: string;
  public playLink: string;
  public inDevelopment: boolean;
  public projectSnippets: ProjectSnippet[];

  public IsPlayable(): boolean{
    return this.playLink !== "";
  }

  public HasCode(): boolean{
    return this.codeLink !== "";
  }
}

export class ProjectSnippet{
  public title: string;
  public imagePath: string;
  public snippetText: string;
}