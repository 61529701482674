<app-site-banner (closeAll)="closeCurrentProject()" class="sticky-banner"></app-site-banner>
<ng-template ngFor let-item [ngForOf]="allButtons" let-i="index">
    <div id="{{'title' + i}}" class="project-title">
        <h4>
            {{this.projectTitles[i]}}  
        </h4>
    </div>
    <div id="{{'project' + i}}" class="project-button" 
        (mouseenter)="buttonEnter($event, i)" 
        (mouseleave)="buttonExit($event, i)" 
        (mousedown)="buttonClick($event, i)">

        <img src="{{this.buttonImages[i]}}" alt="button-image" class="project-button-graphic">
        <app-project-overview class="project-content" [projectId]="i" #cmp></app-project-overview>
    </div>
    <div id="{{'close' + i}}" class="project-button close-button" 
        (mouseenter)="closeButtonEnter($event, i)" 
        (mouseleave)="closeButtonExit($event, i)" 
        (mousedown)="closeButtonClick($event, i)">
        <img src="../../assets/close-round-icon.png" alt="button-image" class="project-button-graphic">
    </div>
</ng-template>
<app-about-me class="about-me"></app-about-me>
