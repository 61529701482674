<div class="site-title">
    <h1>Zack Turman - Game Programmer</h1>
</div>
<div class="banner-container">
    <div class="profile-picture-container">
        <img (click)="profilePictureClick()" class="profile-picture" src="../../assets/profile-picture.png">
    </div>
    <div class="socials-container">
        <a href="https://www.linkedin.com/in/zkturman/"><img class="social-icon" src="../../assets/linkedin-app-icon.png"></a>
        <a href="https://www.youtube.com/channel/UCvD8IT8uwMliNHQK7Bg6GbQ"><img class="social-icon icon-background" src="../../assets/youtube-color-icon.png"></a>
        <a href="https://www.threads.net/@zkturman"><img class="social-icon" src="../../assets/threads-app-icon.png"></a>
    </div>

</div>
