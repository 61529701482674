<div id="project-container{{projectId}}" class="project-container">
    <div class="project-element project-contents">
        <div class="snippet-section">
            <h1 class="project-element">
                {{this.projectInfo.title}}
            </h1>
            <h3 *ngIf="this.projectInfo.inDevelopment">
                Under Construction
            </h3>
            <div class="project-element project-summary-left project-summary-general">
                <iframe [src]="this.projectInfo.sanitizedVideoLink" frameborder="0" class="project-element project-video" allowfullscreen></iframe>
                <div class="project-element overall-summary">
                    <p>
                        {{this.projectInfo.projectSummary}}
                    </p>
                    <div class="external-button-container">
                        <a *ngIf="this.projectInfo.IsPlayable()" href="{{this.projectInfo.playLink}}" class="project-external-button" 
                            (mouseenter)="buttonHover($event)" (mouseleave)="buttonExit($event)">
                            Play
                        </a>
                        <a *ngIf="this.projectInfo.HasCode()" href="{{this.projectInfo.codeLink}}" class="project-external-button"
                        (mouseenter)="buttonHover($event)" (mouseleave)="buttonExit($event)">
                            Code
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let snippet of this.projectInfo.projectSnippets; index as i; let odd = odd; let even = even" class="snippet-section">
            <h2>
                {{this.projectInfo.projectSnippets[i].title}}
            </h2>
            <div [ngClass]="{'project-summary-right': even, 'project-summary-left': odd}" class="project-summary-general">
                <img class="snippet-image" [src]="this.projectInfo.projectSnippets[i].imagePath" alt="imageInfo" 
                    (mouseenter)="snippetHover($event)" (mouseleave)="snippetExit($event)">
                <p>
                    {{this.projectInfo.projectSnippets[i].snippetText}}
                </p>
            </div>
        </div>
    </div>
</div>