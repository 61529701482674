import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-site-banner',
  templateUrl: './site-banner.component.html',
  styleUrls: ['./site-banner.component.css']
})
export class SiteBannerComponent implements OnInit {
  @Output() closeAll: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  profilePictureClick(){
    this.closeAll.emit(null);
  }
}
