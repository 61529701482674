import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ProjectOverviewComponent } from '../project-overview/project-overview.component';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-portfolio-home',
  templateUrl: './portfolio-home.component.html',
  styleUrls: ['./portfolio-home.component.css']
})
export class PortfolioHomeComponent implements OnInit, AfterViewInit {
  private isOpen: boolean;
  @ViewChildren(ProjectOverviewComponent) projectPages!: QueryList<ProjectOverviewComponent>;
  private currentButton: number;
  allButtons = ["0", "1", "2", "3"];
  projectTitles = ["Under Rail", "Brass Necessity", "Buccaneers", "Jack-in-a-Lantern"];
  buttonImages = ["../assets/URButtonImage.png", "../assets/BNButtonImage.png", "../assets/BCButtonImage.png", "../assets/JLButtonImage.png"];
  constructor() {
    this.currentButton = -1;
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.openProject(0);
  }

  buttonEnter(event: any, i: number){
    if (this.currentButton != i){
      var element = document.getElementById("project" + i.toString());
      element.classList.add("button-hover");
    }
  }

  closeButtonEnter(event: any, i: number){
    if (this.currentButton == i){
      var element = document.getElementById("close" + i.toString());
      element.classList.add("button-hover");
    }
  }

  buttonExit(event: any, i: number){
    var element = document.getElementById("project" + i.toString());
    element.classList.remove("button-hover");
  }

  closeButtonExit(event: any, i: number){
    if (this.currentButton == i){
      var element = document.getElementById("close" + i.toString());
      element.classList.remove("button-hover");
    }
  }

  buttonClick(event: any, i: number){
    if (this.currentButton < 0){
      this.openProject(i);
    }
    else if (i != this.currentButton){
      this.closeProject(this.currentButton);
      this.openProject(i);
    }
    this.isOpen = !this.isOpen;
  }

  closeButtonClick(event: any, i: number){
    if (this.currentButton == i){
      this.closeProject(this.currentButton);
      var element = document.getElementById("close" + i.toString());
      element.classList.remove("button-hover");
      element.classList.add("close-button-down");

    }
  }

  openProject(idToOpen: number){
    this.switchProjectOpenStatus(idToOpen, true);
    this.currentButton = idToOpen;
    var element = document.getElementById("close" + idToOpen.toString());
    element.classList.remove("close-button-down");
    var element = document.getElementById("title" + idToOpen.toString());
    element.classList.add("hide-title");
  }

  closeProject(idToClose: number){
    this.switchProjectOpenStatus(idToClose, false);
    var element = document.getElementById("title" + idToClose.toString());
    element.classList.remove("hide-title");
    this.currentButton = -1;
  }

  closeCurrentProject(){
    this.closeProject(this.currentButton);
  }

  switchProjectOpenStatus(idToChange: number, shouldOpen: boolean){
    var element = document.getElementById("project" + idToChange.toString());
    element.classList.remove("button-hover");
    this.addButtonDownClass(element, shouldOpen);
    var contentElement = element.querySelector(".project-content");
    this.addButtonDownClass(contentElement, shouldOpen);
    var graphicElement = element.querySelector(".project-button-graphic");
    this.addButtonDownClass(graphicElement, shouldOpen);
    if (shouldOpen){
      this.projectPages.get(idToChange).activateChildren();
    }
    else{
      this.projectPages.get(idToChange).deactivateChildren();
    }
    this.currentButton = idToChange;
  }

  addButtonDownClass(elementToChange: Element, shouldOpen: boolean){
    if (shouldOpen){
      elementToChange.classList.add("button-down");
    }
    else{
      elementToChange.classList.remove("button-down");
    }
  }
}
