import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ProjectDataFactory } from '../project-data-factory';
import { ProjectInformation } from '../project-information';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit {
  @Input() projectId = -1;
  projectInfo : ProjectInformation;
  isActive: boolean;
  activeColour = "blue";
  inactiveColour = "purple";

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    var factory = new ProjectDataFactory();
    this.projectInfo = factory.CreateProjectData(this.projectId);
    this.projectInfo.sanitizedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.projectInfo.rawVideoLink);
    this.isActive = false;
  }

  ngAfterViewInit(): void{
    this.assignContainerColour(this.inactiveColour);
  }

  assignContainerColour(colourToAssign: string){
    var backgroundElement = document.getElementById("project-container" + this.projectId.toString());
    switch(colourToAssign){
      case "grey":
        backgroundElement.classList.add("grey");
        break;
      case "blue":
        backgroundElement.classList.add("blue");
        break;
      case "pink":
        backgroundElement.classList.add("pink");
        break;
      case "green":
        backgroundElement.classList.add("green");
        break;
      case "purple":
        backgroundElement.classList.add("purple");
        break;
      default:
        backgroundElement.classList.add("blue");
        break;
    }
  }

  removeContainerColour(){
    var backgroundElement = document.getElementById("project-container" + this.projectId.toString());
    backgroundElement.classList.remove("grey", "blue", "pink", "green", "purple");
  }

  activateChildren(){
    if (!this.isActive){
      this.removeContainerColour();
      this.assignContainerColour(this.activeColour);
      var elements = document.querySelectorAll("#project-container" + this.projectId.toString() + " .project-element");
      for (var i = 0; i < elements.length; i++){
        elements[i].classList.add("activated");
      }
      this.isActive = true;
    }
  }

  deactivateChildren(){
    if (this.isActive){
      this.removeContainerColour();
      this.assignContainerColour(this.inactiveColour);
      var elements = document.querySelectorAll("#project-container" + this.projectId.toString() + " .project-element");
      for (var i = 0; i < elements.length; i++){
        elements[i].classList.remove("activated");
      }
      this.isActive = false;
    }
  }

  buttonHover(event:any){
    event.target.classList.add("button-zoom");
  }

  
  buttonExit(event:any){
    event.target.classList.remove("button-zoom");
  }

  snippetHover(event: any){
    event.target.classList.add("snippet-zoom");
  }

  snippetExit(event: any){
    event.target.classList.remove("snippet-zoom");
  }

}
