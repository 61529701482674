import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortfolioHomeComponent } from './portfolio-home/portfolio-home.component';
import { ProjectOverviewComponent } from './project-overview/project-overview.component';
import { SiteBannerComponent } from './site-banner/site-banner.component';
import { AboutMeComponent } from './about-me/about-me.component';

@NgModule({
  declarations: [
    AppComponent,
    PortfolioHomeComponent,
    ProjectOverviewComponent,
    SiteBannerComponent,
    AboutMeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
