import { ProjectInformation, ProjectSnippet } from "./project-information";
export class ProjectDataFactory{
    private projectData = [
        {
            "id": 0,
            "title": "Under Rail",
            "videoLink": "https://www.youtube.com/embed/Pe_iNf6BC_0?mute=1",
            "codeLink": "https://github.com/zkturman/HalloweenJam",
            "playLink": "https://www500.itch.io/under-rail",
            "inDevelopment": "false",
            "backgroundColour": "blue",
            "projectSummary": "Under Rail is the story of a sole survivor who must navigate out of "
                + "the underworld by studying the undead. This game was completed for a game jam "
                + "over a period of three weeks. The team consisted of two people with my club, the "
                + "Bristol Game Creators' Club. I acted as lead developer and team lead for the game.",
            "projectSnippets": [
                {
                    "title": "Adventure Journal",
                    "imagePath": "../assets/UR_JournalMechanics.gif",
                    "text": "One of the core mechanics I developed was the player's journal. Players can open "
                    + "the journal throughout the game to check which enemies they've encountered. When "
                    + "players get close enough to enemies, journal entries are automatically updated, and players "
                    + "can view the description of each monster. In order to win the game, the player "
                    + "must match the description of these monsters to clues given earlier in the game. Finally, "
                    + "once a monster is identified, it is given an overworld effect with a unique colour to  let "
                    + "players know which monsters they've already found."
                },
                {
                    "title": "Dialogue System",
                    "imagePath": "../assets/UR_DialogueMechanics.gif",
                    "text": "In order to the progress the game, players must interact with the demon Unomos. "
                    + "To accomplish this goal, I created a dialogue system that will display different dialogue "
                    + "throughout the game depending on the player's progress. The dialogue system was designed to "
                    + "work with multiple NPCs and limits player controls when engaged."
                },
                {
                    "title": "Attack Mechanics",
                    "imagePath": "../assets/UR_AttackMechanics.gif",
                    "text": "While one objective of the game is to identify monsters in your journal, the primary "
                    + "one is to collect crystals from specific monsters. These can only be acquired by attacking "
                    + "certain ones with a magical skull, the Effulgent Skull. I developed this weapon to "
                    + "strike enemies within a certain radius and to drop crystals if they match the ones identified by Unomos."
                }
            ]
        },
        {
            "id": 1,
            "title": "Brass Necessity",
            "videoLink": "https://www.youtube.com/embed/GNUrWr4JNHU?mute=1",
            "codeLink": "https://github.com/zkturman/BrassNecessity",
            "playLink": "https://www500.itch.io/brass-necessity-demo",
            "inDevelopment": "true",
            "backgroundColour": "pink",
            "projectSummary": "In Brass Necessity, players must face onslaughts of enemies and solve "
                + "puzzles in order to exit a gauntlet. The game is inspired by science fiction and "
                + "is an ongoing project for the Bristol Game Creators' Club. " 
                + "The project was designed to allow members with different specialties to contribute development and design to "
                + "a game by utilising tools like GitHub. I acted as the lead developer and team lead for "
                + "the game. While it is still in development, the game will be published on Steam to show off the club's "
                + "hard work.",
            "projectSnippets": [
                {
                "title": "Laser Mechanics",
                "imagePath": "../assets/BN_LaserMechanics.gif",
                "text": "Brass Necessity is primarily a combat game which centres around the player's laser gun. I "
                + " designed and developed the core mechanics for the laser. When it fires, the laser will instantly "
                + "travel to the first object in front of the laser and display a collision particle effect. Additionally, "
                + "I developed an element system for the game. When players collect elemental crystals, they can load them "
                + "into their laser to change its element, which changes the color of the laser when fired. I created an "
                + "overheat mechanism for the weapon, "
                + "which destroys elemental crystals if the laser is fired continuously for too long. This adds an element of "
                + "resource management."
                },
                {
                "title": "Portal Events",
                "imagePath": "../assets/BN_PortalMechanics.gif",
                "text": "To create puzzle-like barriers for players, I developed various portals for the game. Some "
                + "allow the player to travel back and forth between a pair of portals. Others allow one-way travel only. "
                + "Portals can exist in different states, such as hidden, disabled, and enabled. Game events, which are "
                + "configurable in the Unity editor, can be used to trigger these different states. For example, one-way "
                + "portals can be activated when all enemies are slain so players can teleport to the next level."
                },
                {
                "title": "Element Combat Matchups",
                "imagePath": "../assets/BN_ElementMatchups.gif",
                "text": "In addition to adding elements to the laser, I also added elements to the enemies, "
                + "which are illustrated by the colour of the crystals on the enemies' bodies. The matchup between the "
                + "laser and the enemy creates different effectiveness for the laser. Some elements resist specific elements, "
                + "while others succomb to different elements. These type matchups are represented in-game by the speed of "
                + "enemy health depletion, the size of the laser impact effect, and the pitch of the laser sound effect. "
                + "Through experimentation, players are meant to discover which elements are best to use in different scenarios."
                },
                {
                "title": "Playable Characters",
                "imagePath": "../assets/BN_CharacterSelection.gif",
                "text": "At the beginning of the game, players can choose to play the game as different characters. I "
                + "developed a system that would load in a character with pre-defined settings. For example, each "
                + "character has a different default laser element, which changes the flow of the game."
                }
            ]
        },
        {
            "id": 2,
            "title": "Buccaneers",
            "videoLink": "https://www.youtube.com/embed/GzgVAGUSla8?mute=1",
            "codeLink": "https://github.com/zkturman/Buccaneers",
            "playLink": "",
            "inDevelopment": "true",
            "backgroundColour": "green",
            "projectSummary": "Buccaneers is a two-player board game that can be played online. The game hinges on "
            + "on a customisable animal, called a Beastie, which changes how you play the game. Once selecting their character, "
            + "players then battle it out on an ever-shrinking gameboard. The last animal standing wins! "
            + "This game was an independent project during a game programming bootcamp through Mastered.",
            "projectSnippets": [
                {
                "title": "Customisable Characters",
                "imagePath": "../assets/BC_CharacterSelection.gif",
                "text": "Customisable characters give Buccaneers variety and add complexity to the gameplay mechanics. "
                + "Although the custimisations create aesthetic changes to the animals, they also impact the way the game "
                + "is played. Customisations carry different stat bonuses and special effects, which vary the "
                + "actions players can take during each turn. Some effect grant greater move distance, while others cause "
                + "lingering poison damage. Customisations are configured in a custom menu created with Unity's UI Toolkit."
                },
                {
                "title": "Networked Gameplay (in progress)",
                "imagePath": "../assets/BC_NetworkedGameplay.gif",
                "text": "I used the Photon networking library to create an online two-player experience for the game. "
                + "When players take certain actions in game, messages are sent to the other player. These messages contain "
                + "information about animal customisation and player actions. For example, moving to a new square or attacking "
                + "the opponent sends a message to the opposing player. The opposing player's game then interprets this message "
                + "and simulates the changes locally."
                },
                {
                "title": "Gameboard Mechanics",
                "imagePath": "../assets/BC_GameBoardMechanics.gif",
                "text": "Once the players have configured their animals, they start the board game. The board consists of "
                + "a 6x6 grid of squares. Players always start in diagonally opposing corners. To improve the user "
                + "experience of interacting with the board, I highlight tiles that are valid the for the user's current action. "
                + "When users click a valid tile, the animal will perform the corresponding action. A user interface automatically updates "
                + "to show the player state. This help indicate when players can take their next action."
                }
            ]
        },
        {
            "id": 3,
            "title": "Jack-in-a-Lantern",
            "videoLink": "https://www.youtube.com/embed/ZTerxUNNvW0?mute=1",
            "codeLink": "https://github.com/zkturman/JackInALantern",
            "playLink": "",
            "inDevelopment": "true",
            "backgroundColour": "purple",
            "projectSummary": "Jack-in-a-Lantern is the ultimate game for Halloween and horror film fans. As players complete levels, the "
            + "player's in-game decisions will be used to choose horror fil to watch. Each level has its own premise and its "
            + "own mysteries to solve. Play the game alone or play it with a group to decide your horror experience for the evening.",
            "projectSnippets": [
                {
                "title": "Web APIs",
                "imagePath": "../assets/JL_WebAPIs.gif",
                "text": "The game will utilise web APIs to direct the player to their chosen film when completing a level. Available movies "
                + "will be selected from a database of horror films across different platforms."
                },
                {
                    "title": "Decision Algorithms",
                    "imagePath": "../assets/JL_DecisionMechanics.gif",
                    "text": "While playing the game, player decision will constantly be tracked. Different decision points will be weighed "
                    + "throughout the level. Once finished, the winning game will be revealed."
                }                
            ]
        }
    ]

    public CreateProjectData(id: number): ProjectInformation{
        var projectInfo = new ProjectInformation();
        projectInfo.id = this.projectData[id].id;
        projectInfo.title = this.projectData[id].title;
        projectInfo.rawVideoLink = this.projectData[id].videoLink;
        projectInfo.codeLink = this.projectData[id].codeLink;
        projectInfo.playLink = this. projectData[id].playLink;
        projectInfo.inDevelopment = this.projectData[id].inDevelopment === "true";
        projectInfo.backgroundColour = this.projectData[id].backgroundColour;
        projectInfo.projectSummary = this.projectData[id].projectSummary;
        projectInfo.projectSnippets = new Array();
        for (let i = 0; i < this.projectData[id].projectSnippets.length; i++){
            var snippet = new ProjectSnippet();
            snippet.title = this.projectData[id].projectSnippets[i].title;
            snippet.imagePath = this.projectData[id].projectSnippets[i].imagePath;
            snippet.snippetText = this.projectData[id].projectSnippets[i].text;
            projectInfo.projectSnippets.push(snippet);
        }
        console.log("success");
        return projectInfo;
    }
}